<template>
  <div class="d-flex">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="mr-2" style="cursor: pointer" id="set-location" color="rgba(255, 255, 255, 0.7)" v-bind="attrs" v-on="on">mdi-map-marker</v-icon>
      </template>
      <span>Use Current Location</span>
    </v-tooltip>
    <v-text-field style="font-size:13px" placeholder="Your Location" id="autocomplete" type="text" v-model="location" :rules="[utils.requiredRule]"></v-text-field>
  </div>
</template>
  
<script>

  import Utils from '@/services/Utils.js'
  export default {

    data () {
      return {
        location: null,
        autocompleteInput: null,
        setLocationButton: null,
        autocomplete: null,
        selectedLocation: {},
      }
    },

    created() {
    },

    mounted() {
      this.autocompleteInput = document.querySelector('#autocomplete');
      this.autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteInput,
        {
          types: ['(cities)'],
          fields: ['address_components','geometry.location']
        }
      )
      this.autocomplete.addListener('place_changed', () => {
        this.changePlace()
      })
      this.setLocationButton = document.getElementById('set-location')
      this.setLocationButton.addEventListener('click', () => {
        this.setLocation();
      })
    },
    
    methods: {

      setLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.savePosition);
        }
      },

      savePosition(position) {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.selectedLocation.latitude = position.coords.latitude;
        this.selectedLocation.longitude = position.coords.longitude;
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({ 'location': pos }, this.geoCodeCallback);
        this.$emit('location', this.selectedLocation)
      },

      changePlace() {
        let place = this.autocomplete.getPlace();
        this.selectedLocation.city = this.findLocationComponent(place.address_components, "locality", "long_name");
        this.selectedLocation.country = this.findLocationComponent(place.address_components, "country", "long_name");
        this.selectedLocation.state = this.findLocationComponent(place.address_components, "administrative_area_level_1", "long_name");
        this.selectedLocation.countryCode = this.findLocationComponent(place.address_components, "country", "short_name");
        this.selectedLocation.latitude = place.geometry.location.lat();
        this.selectedLocation.longitude = place.geometry.location.lng();
        this.location = this.selectedLocation.city + ", " + this.selectedLocation.state;
        this.$emit('location', this.selectedLocation)
      },

      findLocationComponent(address_components, type, field) {
        for (let address_component of address_components) {
          for (let address_component_type of address_component.types) {
            if (address_component_type == type) {
              return address_component[field];
            }
          }
        }
      },

      geoCodeCallback(results, status) {
        if (!(status === 'OK')) {
          console.log("geocode error", status, result);
          return;
        }
        const firstResult = results[0];
        if (!firstResult) {
          console.log("no geocode results", status, result);
          return;
        }
        this.selectedLocation.city = this.findLocationComponent(firstResult.address_components, "postal_town", "long_name");
        this.selectedLocation.country = this.findLocationComponent(firstResult.address_components, "country", "long_name");
        this.selectedLocation.state = this.findLocationComponent(firstResult.address_components, "administrative_area_level_1", "long_name");
        this.selectedLocation.countryCode = this.findLocationComponent(firstResult.address_components, "country", "short_name");
        this.location = this.selectedLocation.city + ", " + this.selectedLocation.state;
      }

    },

    computed: {
      utils: function () {
        return Utils;
      },
    },
  }
</script>
